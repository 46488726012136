import React, { Component } from 'react';
import './UnderConstructionPage.scss';
import UnderConstructionAnimation from '../global/UnderConstructionAnimation';
import logo from '../../../assets/images/fabster_svg.svg';
import { CSSTransition } from 'react-transition-group';
class UnderConstructionPage extends Component {
    render() {
        return (React.createElement("div", { className: 'UnderConstructionPage row g-0' },
            React.createElement("div", { className: 'col-12 col-lg-6 uc-left-side g-0' },
                React.createElement("div", { className: 'logo-container' },
                    React.createElement("img", { className: 'top-logo', src: logo }),
                    React.createElement("div", { className: 'logo-name' }, "fabster")),
                React.createElement(CSSTransition, { classNames: "enter-scene-animation", in: true, appear: true, timeout: 2000 },
                    React.createElement("div", { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' } },
                        React.createElement("div", { className: 'working-hard-title', style: { fontVariant: 'small-caps' } }, "P2P MANUFACTURING"),
                        React.createElement(CSSTransition, { classNames: "enter-scene-animation-long", in: true, appear: true, timeout: 2000 },
                            React.createElement("div", { className: 'working-hard-description' },
                                "fabster is part of the autonom.me ecosystem",
                                React.createElement("br", null),
                                "we are in beta testing")),
                        React.createElement(CSSTransition, { classNames: "enter-scene-animation-long", in: true, appear: true, timeout: 2000 },
                            React.createElement("div", { className: 'launch-button', onClick: (event) => { window.open('https://app.autonom.me', '_blank'); }, style: { pointerEvents: 'all' } },
                                React.createElement("div", { className: 'launch-button-text' }, "Launch Dapp"),
                                React.createElement("div", { className: 'launch-button-inside' },
                                    React.createElement("i", { className: 'fa-solid fa-link link-icon' })))))))));
    }
}
export default UnderConstructionPage;
